import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../../components/seo"
import Layout from "../../../app/layout"

import { Row, Col, Breadcrumb } from "antd"

import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"
import Newsletter from "../../../components/Newsletter"
import BlogMasthead from "../../../components/BlogMasthead"
import ScrollAnimation from "react-animate-on-scroll"
import PageNotFound from "../../404"
import { useI18next } from "gatsby-plugin-react-i18next"

const SinglePage = ({
  pageContext: { data: dataAll = {}, language = "id" },
}) => {
  const { t } = useI18next()
  const [backto, setBackto] = useState("")
  const data = dataAll[language]

  useEffect(() => {
    setBackto((window.location.search || "").replace("?cat=", ""))
  }, [])
  if (!data) return <PageNotFound />
  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.description}
        meta={{ image: data.image }}
      />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={backto || "/informasi/berita/"}>{t("Berita")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <BlogMasthead
        title={data.title}
        image={data.image}
        category={data.news_category}
        date={data.created_at}
        author={data.writer_name}
        photographer={data.photographer_name}
        imageCaption={data.caption_image}
      />
      <Container>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <article className="article section text-medium">
            <Row
              gutter={40}
              justify="space-between"
              style={{ margin: "0 -15px" }}
            >
              <Col span={24} md={12} style={{ padding: "0 15px" }}>
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
              </Col>

              <Col span={24} md={8} style={{ padding: "0 15px" }}>
                <div offsetTop={80} className="sticky-sidebar disable-mobile">
                  <Newsletter />
                </div>
              </Col>
            </Row>
          </article>
        </ScrollAnimation>
      </Container>
    </Layout>
  )
}

export default SinglePage

export const query = graphql`
  query NewsSingleQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
